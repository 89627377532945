import { useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { v4 as uuid } from "uuid";
import MenuLink from "./MenuLink";
import adminSidebarData from "./adminSidebarData";
import staffSidebarData from "./staffSidebarData";

const Sidebar = () => {
  const userType = useSelector((state) => state.user.userType);
  const [isOpen, setIsOpen] = useState(false);

  const sidebarData =
    userType === "admin" ? adminSidebarData : staffSidebarData;
  return (
    <div>
      <nav
        className={cn(
          { "w-full": isOpen, "sm:w-72": isOpen, "w-[4.5rem]": !isOpen },
          "sidebar"
        )}
        onMouseOver={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <div className={cn("flex flex-col p-2 ml-5 w-full")}>
          {sidebarData.map((group, i) => {
            return (
              <ul key={i}>
                {group.map((menu) => {
                  if (menu.hasSubMenus) {
                    return (
                      <MenuLink
                        menu={menu}
                        hasSubMenus={menu.hasSubMenus}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        key={uuid()}
                      />
                    );
                  } else {
                    return (
                      <MenuLink
                        menu={menu}
                        key={uuid()}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                      />
                    );
                  }
                })}
              </ul>
            );
          })}
        </div>
      </nav>
    </div>
  );
};
export default Sidebar;
