import {
  RectangleGroupIcon,
  PencilSquareIcon,
  NewspaperIcon,
  UserGroupIcon,
  ArrowLeftOnRectangleIcon,
  DocumentMagnifyingGlassIcon,
  ShieldCheckIcon,
  DocumentTextIcon,
  DocumentCheckIcon,
  DocumentArrowUpIcon,
  Cog6ToothIcon,
  HomeModernIcon,
  ChartBarIcon,
  BookOpenIcon,
  ChatBubbleBottomCenterTextIcon,
  Square3Stack3DIcon,
  PhotoIcon,
  PlusCircleIcon,
  TableCellsIcon,
  VideoCameraIcon,
  TagIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/outline";

const staffSideBarData = [
  [
    {
      id: "dashboard",
      name: "Dashboard",
      link: "/",
      hasSubMenus: false,
      icon: <RectangleGroupIcon className="w-6 h-6" />,
    },
    {
      id: "listBlogs",
      name: "Blogs",
      link: "/blog/list-blogs",
      hasSubMenus: true,
      icon: <NewspaperIcon className="w-6 h-6" />,
      subMenus: [
        {
          id: "listBlogs",
          name: "List",
          link: "/blog/list-blogs",
          icon: <DocumentTextIcon className="w-6 h-6" />,
        },
        {
          id: "addBlog",
          name: "Add Blog",
          link: "/blog/add",
          icon: <PencilSquareIcon className="w-6 h-6" />,
        },
        {
          id: "comments",
          name: "Comments",
          link: "/blog/comments",
          icon: <ChatBubbleBottomCenterTextIcon className="w-6 h-6" />,
        },
        {
          id: "blogCategories",
          name: "Categories",
          link: "/blog/category",
          icon: <TagIcon className="w-6 h-6" />,
        },
      ],
    },
    {
      id: "travelPhotos",
      name: "Travel Photos",
      link: "/travel-photos/list",
      hasSubMenus: true,
      icon: <PhotoIcon className="w-6 h-6" />,
      subMenus: [
        {
          id: "listTravelPhotos",
          name: "List",
          link: "/travel-photos/list",
          icon: <TableCellsIcon className="w-6 h-6" />,
        },
        {
          id: "addTravelPhoto",
          name: "Add Travel Photo",
          link: "/travel-photos/add",
          icon: <PlusCircleIcon className="w-6 h-6" />,
        },
      ],
    },
    {
      id: "travelVides",
      name: "Travel Videos",
      link: "/travel-videos/list",
      hasSubMenus: true,
      icon: <VideoCameraIcon className="w-6 h-6" />,
      subMenus: [
        {
          id: "listTravelVideos",
          name: "List",
          link: "/travel-videos/list",
          icon: <TableCellsIcon className="w-6 h-6" />,
        },
        {
          id: "addTravelVideo",
          name: "Add Travel Video",
          link: "/travel-videos/add",
          icon: <PlusCircleIcon className="w-6 h-6" />,
        },
      ],
    },
    // {
    //   id: "reviewBlogs",
    //   name: "Review",
    //   link: "/review-blogs-requests",
    //   icon: <DocumentMagnifyingGlassIcon className="w-6 h-6" />,
    //   hasSubMenus: true,
    //   subMenus: [
    //     {
    //       id: "reviewBlogs",
    //       name: "Requests",
    //       link: "/review-blogs-requests",
    //       icon: <DocumentArrowUpIcon className="w-6 h-6" />,
    //     },
    //     {
    //       id: "approvedBlogs",
    //       name: "Approved",
    //       link: "/approved-blogs",
    //       icon: <DocumentCheckIcon className="w-6 h-6" />,
    //     },
    //   ],
    // },
    {
      id: "image-gallery",
      name: "Image Gallery",
      link: "/image-gallery",
      hasSubMenus: false,
      icon: <Square3Stack3DIcon className="w-6 h-6" />,
    },
    {
      id: "news-letter",
      name: "Newsletter",
      link: "/news-letter",
      hasSubMenus: false,
      icon: <NewspaperIcon className="w-6 h-6" />,
    },

    {
      id: "servicePackage",
      name: "Service Package",
      icon: <ChartBarIcon className="w-6 h-6" />,
      hasSubMenus: true,
      subMenus: [
        {
          id: "packages",
          name: "Packages",
          link: "/service-package",
        },
        {
          id: "packageInquiry",
          name: "Package Inquiry",
          link: "/package-inquiry",
        },
      ],
    },

    // {
    //   id: "adminUsers",
    //   name: "Admins",
    //   link: "/admin-users",
    //   hasSubMenus: false,
    //   icon: <ShieldCheckIcon className="w-6 h-6" />,
    // },
    // {
    //   id: "staffsUsers",
    //   name: "Staffs",
    //   link: "/staffs-users",
    //   icon: <UserGroupIcon className="w-6 h-6" />,
    //   hasSubMenus: false,
    // }
  ],
  [
    // {
    //   id: "settings",
    //   name: "Settings",
    //   link: "/settings",
    //   hasSubMenus: false,
    //   icon: <Cog6ToothIcon className="w-6 h-6" />,
    // },
    {
      id: "logout",
      name: "",
      link: "",
      hasSubMenus: false,
      icon: "",
      // spacing in sidebar between pokhara buzz and clinchtech
    },
    {
      id: "clinchtech",
      name: "ClinchTech",
      className: "mt-4",
      link: "/clinchtech",
      hasSubMenus: false,
      icon: <HomeModernIcon className="w-6 h-6" />,
    },
    {
      id: "career",
      name: "Career",
      icon: <ChartBarIcon className="w-6 h-6" />,
      hasSubMenus: true,
      subMenus: [
        {
          id: "careerList",
          name: "Career List",
          link: "/career-list",
        },
        {
          id: "careerResponse",
          name: "Career Response",
          link: "/career-response",
        },
      ],
    },
    {
      id: "training",
      name: "Training",
      icon: <BookOpenIcon className="w-6 h-6" />,
      hasSubMenus: true,
      subMenus: [
        {
          id: "trainingList",
          name: "Training List",
          link: "/training-list",
        },
        {
          id: "trainingResponse",
          name: "Training Response",
          link: "/training-response",
        },
      ],
    },
    {
      id: "certificate",
      name: "Certificate",
      icon: <AcademicCapIcon className="w-6 h-6" />,
      hasSubMenus: true,
      subMenus: [
        {
          id: "certificateTemplateList",
          name: "Certificate Templates",
          link: "/certificate-template-list",
        },
        {
          id: "certificatesList",
          name: "Certificate Lists",
          link: "/certificate-list",
        },
      ],
    },

    {
      id: "spacing",
      name: "",
      link: "",
      hasSubMenus: false,
      icon: "",
      // spacing in sidebar between pokhara buzz and clinchtech
    },
    {
      id: "spacing",
      name: "",
      link: "",
      hasSubMenus: false,
      icon: "",
      // spacing in sidebar between pokhara buzz and clinchtech
    },
  ],
];

export default staffSideBarData;
