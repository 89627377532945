import { lazy } from "react";

const Dashboard = lazy(() => import("pages/dashboard/Dashboard"));
const AdminUsers = lazy(() => import("pages/adminUsers/AdminUsers"));
const StaffsUsers = lazy(() => import("pages/staffsUsers/StaffsUsers"));
const Blogs = lazy(() => import("pages/blogs/listBlog/Blogs"));
const EditBlog = lazy(() => import("pages/blogs/editBlog/EditBlog"));
const AddBlog = lazy(() => import("pages/blogs/addBlog/AddBlog"));
const BlogDetails = lazy(() => import("pages/blogs/blogDetails/BlogDetails"));
const Settings = lazy(() => import("pages/settings/Settings"));
const RequestForApprovalBlogs = lazy(() =>
  import("pages/OnReviewBlogs/RequestForApproval/RequestForApproval")
);
const ApprovedBlogs = lazy(() =>
  import("pages/OnReviewBlogs/ApprovedBlogs/ApprovedBlogs")
);

const BlogCategories = lazy(() =>
  import("pages/blogs/blogCategories/BlogCategories")
);

const ImageGallery = lazy(() => import("pages/imageGallery/ImageGallery"));
const BlogComments = lazy(() => import("pages/blogs/comments/BlogComments"));
const Newsletter = lazy(() => import("pages/newsLetter/NewsLetter"));

const TravelPhotos = lazy(() =>
  import("pages/travelPhotos/listTravelPhotos/TravelPhotos")
);
const AddTravelPhoto = lazy(() =>
  import("pages/travelPhotos/addTravelPhoto/AddTravelPhoto")
);
const EditTravelPhoto = lazy(() =>
  import("pages/travelPhotos/editTravelPhoto/EditTravelPhoto")
);
const TravelPhotosDetails = lazy(() =>
  import("pages/travelPhotos/travelPhotosDetail/TravelPhotosDetail")
);

const TravelVideos = lazy(() =>
  import("pages/travelVideo/listTravelVideo/TravelVideo")
);
const AddTravelVideo = lazy(() =>
  import("pages/travelVideo/addTravelVideo/AddTravelVideo")
);
const EditTravelVideo = lazy(() =>
  import("pages/travelVideo/editTravelVideo/EditTravelVideo")
);
const TravelVideosDetails = lazy(() =>
  import("pages/travelVideo/travelVideoDetail/TravelVideoDetail")
);

// ClinchTech Routes

const ClinchTechHome = lazy(() =>
  import("pages/clinchTech/homepage/clinchTechHome")
);
// Career Routes
const CareerList = lazy(() => import("pages/clinchTech/career/CareerList"));
const CareerOpeningDetails = lazy(() =>
  import("pages/clinchTech/career/CareerOpeningDetails")
);
const EditCareer = lazy(() => import("pages/clinchTech/career/EditCareer"));
const AddCareer = lazy(() => import("pages/clinchTech/career/AddCareer"));
const ResponseCareer = lazy(() =>
  import("pages/clinchTech/career/ResponseCareer")
);
const ApplicationForCareer = lazy(() =>
  import("pages/clinchTech/career/ResponseCareerIndividualApplication")
);

// Training Routes
const TrainingList = lazy(() =>
  import("pages/clinchTech/training/TrainingList")
);
const EditTrainingCourse = lazy(() =>
  import("pages/clinchTech/training/EditTraining")
);
const CourseDetailsFileEditForm = lazy(() =>
  import("pages/clinchTech/training/editForms/CourseDetailsFileEditForm")
);

const EditTrainingCourseHome = lazy(() =>
  import("pages/clinchTech/training/EditTrainingCourse")
);
const CourseDetailsEditForm = lazy(() =>
  import("pages/clinchTech/training/editForms/CourseDetailsEditForm")
);
const AddInstructorToTraining = lazy(() =>
  import("pages/clinchTech/training/editForms/AddInstructorToTraining")
);
const ResponseTraining = lazy(() =>
  import("pages/clinchTech/training/ResponseTraining")
);
const AddTraining = lazy(() => import("pages/clinchTech/training/AddTraining"));
const JoinedStudentForTraining = lazy(() =>
  import("pages/clinchTech/training/JoinedStudentForTraining")
);
const TrainingCourseDetails = lazy(() =>
  import("pages/clinchTech/training/trainingCourseDetails")
);

// Clinchtech Certificate
const CertificateList = lazy(() =>
  import("pages/clinchTech/certificates/CertificateList")
);

const CertificateTemplateList = lazy(() =>
  import("pages/clinchTech/certificates/template/CertificateTemplateList")
);
const CertificateTemplateDetails = lazy(() =>
  import("pages/clinchTech/certificates/template/CertificateTemplateDetails")
);

const CertificateTemplateCreation = lazy(() =>
  import("pages/clinchTech/certificates/template/CertificateTemplateCreation")
);

const CertificateTemplateEdit = lazy(() =>
  import("pages/clinchTech/certificates/template/CertificateTemplateEdit")
);
const CertificateTemplateEditPDF = lazy(() =>
  import("pages/clinchTech/certificates/template/CertificateTemplateEditPDF")
);
const CertificateGrant = lazy(() =>
  import("pages/clinchTech/certificates/CertificateGrant")
);

// Service package

const ServicePackage = lazy(() =>
  import("pages/servicePackage/servicePackage/ServicePackage")
);
const CrateServicePackage = lazy(() =>
  import("pages/servicePackage/createServicePackage/CreateServicePackage")
);

const EditServicePackage = lazy(() =>
  import("pages/servicePackage/editServicePackage/EditServicePackage")
);

const InquirePackage = lazy(() =>
  import("pages/servicePackage/inquiryPackage")
);

const InquirePackageDetails = lazy(() =>
  import("pages/servicePackage/inquiryPackage/InquiryPackageDetails")
);

const appRoutes = [
  {
    id: "dashboard",
    path: "/",
    exact: true,
    component: Dashboard,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "listBlogs",
    path: "/blog/list-blogs",
    exact: true,
    component: Blogs,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "editBlogs",
    path: "/blog/edit/:blogId",
    exact: true,
    component: EditBlog,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "addBlogs",
    path: "/blog/add",
    exact: true,
    component: AddBlog,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "comments-list",
    path: "/blog/comments",
    exact: true,
    component: BlogComments,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "blog-categories",
    path: "/blog/category",
    exact: true,
    component: BlogCategories,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "blogDetails",
    path: "/blog/details/:blogId",
    exact: true,
    component: BlogDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "listTravelPhotos",
    path: "/travel-photos/list",
    exact: true,
    component: TravelPhotos,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "addTravelPhotos",
    path: "/travel-photos/add",
    exact: true,
    component: AddTravelPhoto,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "editTravelPhotos",
    path: "/travel-photos/edit/:travelPhotoId",
    exact: true,
    component: EditTravelPhoto,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "travelPhotosDetails",
    path: "/travel-photos/details/:travelPhotoId",
    exact: true,
    component: TravelPhotosDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "listTravelVideos",
    path: "/travel-videos/list",
    exact: true,
    component: TravelVideos,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "addTravelVideos",
    path: "/travel-videos/add",
    exact: true,
    component: AddTravelVideo,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "editTravelVideos",
    path: "/travel-videos/edit/:travelVideoId",
    exact: true,
    component: EditTravelVideo,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "travelVideoDetails",
    path: "/travel-videos/details/:travelVideoId",
    exact: true,
    component: TravelVideosDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "reviewBlogs",
    path: "/review-blogs-requests",
    exact: true,
    component: RequestForApprovalBlogs,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "approvedBlogs",
    path: "/approved-blogs",
    exact: true,
    component: ApprovedBlogs,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "image-gallery",
    path: "/image-gallery",
    exact: true,
    component: ImageGallery,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "news-letter",
    path: "/news-letter",
    exact: true,
    component: Newsletter,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "staffsUsers",
    path: "/staffs-users",
    exact: true,
    component: StaffsUsers,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  // {
  //   id: "addStaffs",
  //   path: "/staffs/add-staff",
  //   exact: true,
  //   component: AddStaffs,
  //   meta: {
  //     appLayout: true,
  //     privateRoute: true,
  //   },
  // },
  // {
  //   id: "editStaff",
  //   path: "/staffs/edit-staff/:id",
  //   exact: true,
  //   component: EditStaff,
  //   meta: {
  //     appLayout: true,
  //     privateRoute: true,
  //   },
  // },
  {
    id: "adminUsers",
    path: "/admin-users",
    exact: true,
    component: AdminUsers,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "settings",
    path: "/settings",
    exact: true,
    component: Settings,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  // Routes of ClinchTech
  {
    id: "clinchtech",
    path: "/clinchtech",
    exact: true,
    component: ClinchTechHome,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  // Career Routes
  {
    id: "careerList",
    path: "/career-list",
    exact: true,
    component: CareerList,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "careerDetails",
    path: "/career/:careerId/details",
    exact: true,
    component: CareerOpeningDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "careerEdit",
    path: "/career/:careerId/edit",
    exact: true,
    component: EditCareer,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "careerResponse",
    path: "/career-response",
    exact: true,
    component: ResponseCareer,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "addCareer",
    path: "/add-career",
    exact: true,
    component: AddCareer,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "careerApplication",
    path: "/career/application/:applicationId",
    exact: true,
    component: ApplicationForCareer,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  // Training routes
  {
    id: "trainingList",
    path: "/training-list",
    exact: true,
    component: TrainingList,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "trainingCourseDetails",
    path: "/training/course/:courseId/details",
    exact: true,
    component: TrainingCourseDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "trainingCourseEdit",
    path: "/training/course/:courseId/edit/v1",
    exact: true,
    component: EditTrainingCourse,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "trainingCourseEdit",
    path: "/training/course/:courseId/edit",
    exact: true,
    component: EditTrainingCourseHome,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "trainingCourseDetailsEditForm",
    path: "/training/course/:courseId/edit/coursedetails",
    exact: true,
    component: CourseDetailsEditForm,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "trainingCourseDetailsFileEditForm",
    path: "/training/course/:courseId/edit/coursedetails/file",
    exact: true,
    component: CourseDetailsFileEditForm,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "trainingCourseAddInstructor",
    path: "/training/course/:courseId/edit/instructor/add",
    exact: true,
    component: AddInstructorToTraining,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "addTraining",
    path: "/training-response",
    exact: true,
    component: ResponseTraining,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "addTraining",
    path: "/add-training-course",
    exact: true,
    component: AddTraining,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "trainingCourseStudent",
    path: `/training/course/:courseId/students`,
    exact: true,
    component: JoinedStudentForTraining,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "certificateTemplateList",
    path: `/certificate-template-list`,
    exact: true,
    component: CertificateTemplateList,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "certificateTemplateCreation",
    path: `/certificate-template/new`,
    exact: true,
    component: CertificateTemplateCreation,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "certificateTemplateDetails",
    path: `/certificate-template/:templateId/details`,
    exact: true,
    component: CertificateTemplateDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "certificateTemplateDetailsEdit",
    path: `/certificate-template/:templateId/edit`,
    exact: true,
    component: CertificateTemplateEdit,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "certificateTemplateDetailsPDFEdit",
    path: `/certificate-template/:templateId/edit/pdf`,
    exact: true,
    component: CertificateTemplateEditPDF,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "certificategrant",
    path: `/certificate/template/:templateId/grant`,
    exact: true,
    component: CertificateGrant,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "certificateList",
    path: `/certificate-list`,
    exact: true,
    component: CertificateList,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "servicePackage",
    path: `/service-package`,
    exact: true,
    component: ServicePackage,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "servicePackage",
    path: `/create-service-package`,
    exact: true,
    component: CrateServicePackage,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "editServicePackage",
    path: `/edit-service-package/:id`,
    exact: true,
    component: EditServicePackage,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "packageInquiry",
    path: `/package-inquiry`,
    exact: true,
    component: InquirePackage,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "packageInquiryDetails",
    path: `/package-inquiry/:id`,
    exact: true,
    component: InquirePackageDetails,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
];

export default appRoutes;
